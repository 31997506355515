<template>
  <div id="app">
    <div class="top"></div>
    
    <div class=" area">
      <Selection></Selection>
    </div> 
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import Selection from './components/selection.vue'

export default {
  name: 'App',
  components: {
      Selection
  }
}
</script>

  
<style>
#app {
  font-family: 'Exo 2', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #2c3e50;
  
 
}


.top {
  height: 35vh;
  background-color: #7fffd4;
  z-index: -1;
}

.area {
  position: relative;
  top: -30vh;

}



</style>
