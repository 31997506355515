const tests = [
    {
        name: "Independent Sample T-test",
        groupNumberOfIndependentVariable: "2",
        targetVarType: "numeric",
        targetVarLoM: "interval",
        isAllGroupsNormal: "normal",
        isGroupsIndependent: "independant",
        isAnyGroupLt10: "no",
        info:"Örn. standart bakım alan diyabet hastaları ile tedavisini video görüşme ile sürdüren diyabet hastalarının HbA1c değerleri(normal dağılıma uygun dağıldığı durumda)"
    },
    {
        name: "Paired Sample T-test",
        groupNumberOfIndependentVariable: "2",
        targetVarType: "numeric",
        targetVarLoM: "interval",
        isAllGroupsNormal: "normal",
        isGroupsIndependent: "dependant",
        isAnyGroupLt10: "no",
        info:"Örn. Video görüşme ile takip edilen tip-2 diyabet hastalarının başlangıçta ve 6. sonundaki HbA1c değerleri(normal dağılıma uygun dağıldığı durumda)"
    },
    {
        name: "Mann-Whitney's U test",
        groupNumberOfIndependentVariable: "2",
        targetVarType: "numeric",
        targetVarLoM: "interval",
        isAllGroupsNormal: "notnormal",
        isGroupsIndependent: "independant",
        isAnyGroupLt10: "yes",
        info: "Kadınların aylık harcama miktarı ile (normal dağılıma uyan) erkeklerin aylık harcama miktarları(normal dağılıma uymayan) "
    },
    {
        name: "Wilcoxon Testi",
        groupNumberOfIndependentVariable: "2",
        targetVarType: "numeric",
        targetVarLoM: "interval",
        isAllGroupsNormal: "notnormal",
        isGroupsIndependent: "dependant",
        isAnyGroupLt10: "yes",
        info:"Örn. Ameliyatın başlangıcında (normal dağılıma uymuyor) ve 30. dakikasında nabız değerleri(normal dağılıma uymuyor) "
    },
    {
        name: "One way ANOVA",
        groupNumberOfIndependentVariable: "2+",
        targetVarType: "numeric",
        targetVarLoM: "interval",
        isAllGroupsNormal: "normal",
        isGroupsIndependent: "independant",
        isAnyGroupLt10: "no",
        info:"Örn. kliniğe başvuran hastaların bir kısmına A tedavisi, bir kısmına B tedavisi uygulanıyor. A tedavisini alan grup, B tedavisini alan grup ve kontrol grubunun ilgili değerleri (nümerik bir değer) eğer her üç grup da normal dağılıyor ise One Way ANOVA testi ile karşılaştırılabilir."
    },
    {
        name: "Kruskal-Wallis test",
        groupNumberOfIndependentVariable: "2+",
        targetVarType: "numeric",
        targetVarLoM: "interval",
        isAllGroupsNormal: "notnormal",
        isGroupsIndependent: "independant",
        isAnyGroupLt10: "yes",
        info:"One Way ANOVA örneğindeki senaryoda B grubu normal dağılmıyor ise o zaman bu gruplar Kruskal-Wallis testi ile karşılaştırılabilir. One Way ANOVA örneğini de ne diyorsanız, yan tarafta kriterleri uygun bir sekilde seçerek görebilirsiniz. :)"
    },
    {
        name: "Repeated Measures ANOVA",
        groupNumberOfIndependentVariable: "2+",
        targetVarType: "numeric",
        targetVarLoM: "interval",
        isAllGroupsNormal: "normal",
        isGroupsIndependent: "dependant",
        isAnyGroupLt10: "no",
        info: "Örn. bir tedavi için başlangıç, 6. ay ve 12.ay sonu yapılan ölçümler bu test ile karşılaştırılabilir, tabi bu üç grup da normal dağılmalı ;)"
    },
    {
        name: "Friedman's test",
        groupNumberOfIndependentVariable: "2+",
        targetVarType: "numeric",
        targetVarLoM: "interval",
        isAllGroupsNormal: "notnormal",
        isGroupsIndependent: "dependant",
        isAnyGroupLt10: "yes",
        info:"Örn. öğrencilerin online derslere yaklaşımını merak ediyoruz. Birinci ay sonunda, dönem sonunda ve yıl sonundaki derslere öğrencilerin ortalama kaç dakika katıldıklarını sistemden elde ettik. Birinci ay ve dönem sonu ölçümleri normal dağılmışken, yıl sonundaki değerlerin normal dağılmadığını görüyoruz. Bu durumda bu testi kullanabiliriz.  "
    }
]


export default tests;