<template>
  <div class="has-background-white-bis ark">
    <div class="hello is-flex is-flex-direction-column is-justify-content-space-between">
    <div class="banner mb-6">
        <h1 class="is-size-1 py-2">TEST SEÇ</h1>
        <h2 class="is-size-3 py-2 has-text-left mx-4">Aşağıdaki sorulara cevap vererek yapmanız gereken istatistiksel testi seçebilirsiniz</h2>
    </div>
     <div class="phone px-3 is-flex is-clipped is-justify-content-space-between">
         <div>
        <div class="criteria px-3 is-flex is-flex-direction-column is-align-content-start">
        <div>
            <h3 class=" is-size-3 has-text-left">Kaç adet grubu karşılaştırıyorsunuz? </h3>
        </div>
          <div class="is-flex m-3">
          <b-radio-button v-model="groupNumberOfIndependentVariable"
                native-value= "2"
                type=" is-info is-light is-outlined">
                <b-icon icon="close"></b-icon>
                <span class="is-size-5" >2</span>
            </b-radio-button>

            <b-radio-button v-model="groupNumberOfIndependentVariable"
                native-value= "2+" 
                class="mx-2"
                type="is-info is-light is-outlined">
                <b-icon icon="fas fa-plus"></b-icon>
                <span class="is-size-5">2'den fazla</span>
            </b-radio-button>
      </div>
     </div>


     
     <div class="is-flex is-flex-direction-column is-align-items-space-between">
        <div>
            <h3 class="m-3 is-size-3 has-text-left">Gruplar birbirinden bağımsız mı?</h3>
        </div>
          <div class="is-flex m-3 ">
          <b-radio-button v-model="isGroupsIndependent"
                native-value = "dependant"
                type="is-danger is-light is-outlined">
                <b-icon icon="close"></b-icon>
                <span class="is-size-5">Hayır</span>
            </b-radio-button>

            <b-radio-button  class="mx-2" v-model="isGroupsIndependent"
                native-value= "independant"
                type="is-success is-light is-outlined">
                <b-icon icon="check"></b-icon>
                <span class="is-size-5" >Evet</span>
            </b-radio-button>
      </div>
      

    <div class="is-flex is-flex-direction-column is-align-items-space-between">
        <div>
            <h3 class="m-3 is-size-3 has-text-left">Grupların dağılımı normal mi?</h3>
        </div>
          <div class="is-flex m-3">
          <b-radio-button v-model="isAllGroupsNormal"
                native-value= "notnormal"
                class="mx-2"
                type="is-danger is-light is-outlined">
                <b-icon icon="close"></b-icon>
                <span class="is-size-5">Hayır</span>
            </b-radio-button>

            <b-radio-button v-model="isAllGroupsNormal"
                native-value= "normal"
                type="is-success is-light is-outlined">
                <b-icon icon="check"></b-icon>
                <span class="is-size-5" >Evet</span>
            </b-radio-button>
      </div>
     </div>


     </div>
     <div class="m-3 is-flex">
         <b-button class="is-info is-medium" @click="filterTests()" v-on:keyup.enter="filterTests" >Göster</b-button>
         <b-button class="is-light is-medium" @click="clearFilters()">Seçimleri temizle</b-button>
     </div>

    </div>
     
    <div  class="content test is-flex is-flex-direction-column is-size-2 is-justify-content-start is-align-content-center">
        <p class="has-text-left is-size-2 title">{{testName.name}}</p>  
        <p class="has-text-left is-size-4">{{testName.info}}</p>  
        
         
    </div> 
     
  </div>
  </div>
  </div>
</template>

<script>
import tests from '@/data.js';

export default {
  name: 'Selection',
  props: {
  },
  data(){
      return {
          tests,
          groupNumberOfIndependentVariable: '',
          isGroupsIndependent: false,
          isAllGroupsNormal: false,
          testName: ''

      };
  },
  methods:{
     snackbar() {
       if(this.isGroupsIndependent == '' || this.isAllGroupsNormal== '' || this.groupNumberOfIndependentVariable == ''){
         this.$buefy.snackbar.open(`Lütfen 3 soruyu da cevapladığınızdan emin olunuz`)
       } else return true
                
            },
      clearFilters(){
         this.isGroupsIndependent == '',
         this.isAllGroupsNormal == '',
         this.groupNumberOfIndependentVariable == ''
      },
      filterTests() {
        if(this.snackbar()){
          let a = this.tests.filter(test => { return test.isGroupsIndependent == this.isGroupsIndependent 
                            && test.isAllGroupsNormal == this.isAllGroupsNormal 
                            && test.groupNumberOfIndependentVariable == this.groupNumberOfIndependentVariable  
        })

        this.testName = a[Object.keys(a)[0]]
        console.log(this.testName)
        }
        
      },
      dene(){
          console.log(this.isAllGroupsNormal)
      }
  },
 /* created() {
    
      this.tests = JSON.parse(tests)
    
  }*/
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

html {
  overflow: hidden;
}


.ark {
  margin-top: 0px;
}

.banner{
  
  overflow: hidden;
}

.ark{
  
  width: 80vw;
  margin:auto;
  
}

.test {
  max-width:60%;
}


@media only screen and (max-width: 480px) {
  .phone {
    flex-direction: column;
  }
  .banner {
    margin-bottom: 40px;
  }
}
</style>
